<template>
  <div class="jyh-contact">
    <contact-header></contact-header>
    <contact-body></contact-body>
    <y-footer></y-footer>
  </div>
</template>

<script>
import ContactHeader from '../components/contact/ContactHeader.vue'
import ContactBody from '../components/contact/ContactBody.vue'
import YFooter from '../components/Footer.vue'
export default {
  components: { ContactHeader, ContactBody, YFooter }
}
</script>

<style lang="css">
</style>
