<template>
  <div class="contact-body view-padding">
    <div class="contact-map">
      <div :class="{'contact-map-inner': mounted}"></div>
    </div>
    <div class="contact-info">
      <h1 class="company">上海徽华医药科技有限公司</h1>
      <h2 class="info-line"><span class="icon icon-1"></span><span>办公地址：上海市黄浦区思南路105号黄埔科创中心415室</span></h2>
      <h2 class="info-line"><span class="icon icon-2"></span><span>客户热线：021-6199-0775</span></h2>
      <h2 class="info-line"><span class="icon icon-2"></span><span>联系电话：18516097297</span></h2>
      <h2 class="info-line"><span class="icon icon-3"></span><span>商务合作：service@yxye.cn</span></h2>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      mounted: false
    }
  },
  mounted () {
    this.mounted = true
  }
}
</script>

<style lang="stylus">
.contact-body
  padding-top 30px
  padding-bottom 50px
  &:after
    content: "."
    visibility: hidden
    display: block
    height: 0
    clear: both

.contact-map
  float left
  width 350px
  height 241.16px
  background #fff url('../../assets/images/map.png') no-repeat
  background-size cover

.contact-map-inner
  height 241.16px
  background url('../../assets/images/map_big.png') no-repeat
  background-size cover

.contact-info
  float left
  margin-left 20px
  width 400px
  h1
    margin 0 0 30px 0
    font-size 20px
    font-family "Helvetica Neue", "Hiragino Sans GB", "Microsoft YaHei", "\9ED1\4F53", Arial, sans-serif
    font-weight lighter
  h2
    margin 20px 0
    height 22px
    line-height 22px
    font-size 14px
    font-weight 300
  .icon-1
    background url('../../assets/images/contact-address.png') no-repeat
    background-size 100%
  .icon-2
    background url('../../assets/images/contact-phone.png') no-repeat
    background-size 100%
  .icon-3
    background url('../../assets/images/contact-cooperation.png') no-repeat
    background-size 100%
  .icon-4
    background url('../../assets/images/contact-wechat.png') no-repeat
    background-size 100%
.info-line
  .icon
    display inline-block
    width 22px
    height 22px
    margin-right 8px
  span
    float left
</style>
